import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";
import TextInput from "../../form/TextInput";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const CalculationList = (props) => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);
    const serviceReportDetails = useSelector(state => state.serviceReport);

    const is_user_super_admin = userProfile.role == 4;

    function handleChange(event) {
        let _fields = {
            [event.target.name]: event.target.value,
        };
        let _serviceReport = {
            ...serviceReportDetails, [event.target.name]: event.target.value
        }

        calculateExGst(_serviceReport);

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function calculateExGst(_serviceReport) {
        let totalMaterialPrice = parseFloat(_serviceReport.totalmprice);
        let quotedPrice = parseFloat(_serviceReport.quotedprice) || 0;
        let serviceCost = parseFloat(_serviceReport.service_cost) || 0;
        let labourCost = _serviceReport.labourcost == null ? 0 : parseFloat(_serviceReport.labourcost);
        let combinedTotal = totalMaterialPrice + quotedPrice + labourCost + serviceCost;

        if(!isNaN(combinedTotal) && combinedTotal >= 0) {
            let _fields = {
                'exgst': parseFloat(combinedTotal).toFixed(2),
            };

            dispatch(setServiceReport(_fields));
            props.updateServiceReport(_fields);
        }
    }

    function isAdminOrTechnician() {
        return userProfile.role == 4 || userProfile.role == 3;
    }

    return (
        <div
            className={`${props.currentLayoutView === "full_width_view" ? "col-4 p-3" : "col-12 mt-3 p-3"} border border-1`}>
            <div className="row align-items-center">
                <div className="col-6 text-left">
                    {serviceReportConstants.TOTAL_MATERIAL_PRICE_LABEL}
                </div>
                <div className="col-6 text-right">
                    <TextInput
                        name="totalmprice"
                        value={serviceReportDetails.totalmprice}
                        onChange=""
                        disabled={true}
                    />
                </div>
            </div>

            <div className="row mt-2 align-items-center">
                <div className="col-6 text-left">
                    {serviceReportConstants.CALL_OUT_QUOTED_PRICE_LABEL}
                </div>
                <div className="col-6 text-right">
                    <TextInput
                        name="quotedprice"
                        value={serviceReportDetails.quotedprice}
                        onChange={(e) => handleChange(e)}
                        disabled={!is_user_super_admin || props.isEditable}
                    />
                </div>
            </div>

            {isAdminOrTechnician() ?
                <div className="row mt-2 align-items-center">
                    <div className="col-6 text-left">
                        {serviceReportConstants.SERVICE_COST_LABEL}
                    </div>
                    <div className="col-6 text-right">
                        <TextInput
                            name="service_cost"
                            value={serviceReportDetails.service_cost}
                            onChange={(e) => handleChange(e)}
                            disabled={!is_user_super_admin || props.isEditable}
                        />
                    </div>
                </div>
            :null}

            <div className="row mt-2 align-items-center">
                <div className="col-6 text-left">
                    {serviceReportConstants.TOTAL_LABOUR_COSTS_LABEL}
                </div>
                <div className="col-6 text-right">
                    <TextInput
                        name="labourcost"
                        value={serviceReportDetails.labourcost}
                        onChange={(e) => handleChange(e)}
                        disabled={!is_user_super_admin || props.isEditable}
                    />
                </div>
            </div>

            <div className="row mt-2 align-items-center">
                <div className="col-6 text-left">
                    {serviceReportConstants.SUB_TOTAL_EXT_GST_LABEL}
                </div>
                <div className="col-6 text-right">
                    <TextInput
                        name="exgst"
                        value={serviceReportDetails.exgst}
                        onChange={(e) => handleChange(e)}
                        disabled={true}
                    />
                </div>
            </div>

            <div className="mt-4">
                <div className="col-6 text-left">
                    {serviceReportConstants.WORKSHOP_SUPERVISOR_SIGNATURE_LABEL}
                </div>
                <div className="text-center mt-2 border border-2">
                    {serviceReportDetails.signature &&
                        <React.Fragment>
                            <div style={{height: "90px"}}>
                                <img
                                    src={`${API_ENDPOINT}assets/images/signatures/${serviceReportDetails.signature}`}
                                    alt="signature"
                                    width={300}
                                    height={90}
                                />
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    );
};

export default CalculationList;
