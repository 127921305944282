// Service Basic Details //

// IDs
export const SR_COMPLETE_YES_ID = "complete_yes";
export const SR_COMPLETE_NO_ID = "complete_no";

// Values
export const SR_COMPLETE_YES_VALUE = "yes";
export const SR_COMPLETE_NO_VALUE = "no";

// Labels
export const SR_COMPLETE_YES_LABEL = "Yes";
export const SR_COMPLETE_NO_LABEL = "No";


/* Service Checklist */
export const SERVICE_CHECKLIST_MAINTENANCE_DESCRIPTION_LABEL = "SERVICE CHECKLIST - MAINTENANCE DESCRIPTION";

// Constants
export const CLEAN_FILTER_CONSTANT = "clean_filter";
export const CHECK_COMPRESSOR_CONSTANT = "check_compressor";
export const CHECK_COMPRESSOR_OIL_CONSTANT = "check_compressor_oil";
export const CHECK_FILTER_DRIER_CONSTANT = "check_filter_drier";
export const CHECK_RECORDED_FAULTS_CONSTANT = "check_recorded_faults";
export const CHECK_HEATER_VALVE_OPERATIONS_CONSTANT = "check_heater_valve_operations";
export const CHECK_BOLTS_CLAMPS_UNIT_SECURITY_CONSTANT = "check_bolts_clamps_unit_security";
export const CHECK_CONDENSER_EVAPORATOR_COILS_CONSTANT = "check_condenser_evaporator_coils";
export const CHECK_CASE_LIDS_CONSTANT = "check_case_lids";
export const FULLY_CLEAN_INSIDE_UNIT_CONSTANT = "fully_clean_inside_unit";
export const CHECK_REFRIGERANT_LEAKS_GAS_CHARGE_CONSTANT = "check_refrigerant_leaks_gas_charge";
export const CHECK_CLEAN_DRAIN_HOSES_CONSTANT = "check_clean_drain_hoses";
export const CHECK_HOSES_CHAFING_DAMAGE_CONSTANT = "check_hoses_chafing_damage";
export const CHECK_SUCTION_DISCHARGE_PRESSURE_CONSTANT = "check_suction_discharge_pressure";
export const CHECK_ELECTRICAL_CONNECTIONS_CONSTANT = "check_electrical_connections";
export const CHECK_HP_LP_PRESSURE_SWITCH_CONSTANT = "check_hp_lp_pressure_switch";
export const CHECK_ELECTRICAL_THERMOSTAT_CONSTANT = "check_electrical_thermostat";
export const CHECK_HEATER_BOOST_PUMPS_CONSTANT = "check_heater_boost_pumps";
export const CHECK_CLUTCH_IDLER_PULLEY_BEARING_CONSTANT = "check_clutch_idler_pulley_bearing";
export const CHECK_EVAPORATOR_CONDENSER_FAN_MOTORS_CONSTANT = "check_evaporator_condenser_fan_motors";
export const FUNCTION_TEST_AC_COOL_HEAT_MODE_CONSTANT = "function_test_ac_cool_heat_mode";

// Labels
export const CLEAN_FILTER_LABEL = "Clean Filter (replace if necessary)";
export const CHECK_COMPRESSOR_LABEL = "Check Compressor / ALT Belt (adjust if necessary)";
export const CHECK_COMPRESSOR_OIL_LABEL = "Check Compressor Oil Level (add oil if required)";
export const CHECK_FILTER_DRIER_LABEL = "Check Filter Drier";
export const CHECK_RECORDED_FAULTS_LABEL = "Check for Recorded Faults";
export const CHECK_HEATER_VALVE_OPERATIONS_LABEL = "Check Heater Valve Operation & for Leaks";
export const CHECK_BOLTS_CLAMPS_UNIT_SECURITY_LABEL = "Check Bolts & Clamps For Unit Security";
export const CHECK_CONDENSER_EVAPORATOR_COILS_LABEL = "Clean Condenser & Evaporator Coils";
export const CHECK_CASE_LIDS_LABEL = "Check Case Lids for Cracks / Damage & Report";
export const FULLY_CLEAN_INSIDE_UNIT_LABEL = "Fully Clean Inside Unit";
export const CHECK_REFRIGERANT_LEAKS_GAS_CHARGE_LABEL = "Check Refrigerant For Leaks & Check Gas Charge";
export const CHECK_CLEAN_DRAIN_HOSES_LABEL = "Check & Clean All Drain Hoses";
export const CHECK_HOSES_CHAFING_DAMAGE_LABEL = "Check Hoses for Chafing Damage";
export const CHECK_SUCTION_DISCHARGE_PRESSURE_LABEL = "Check Suction & Discharge Pressure";
export const CHECK_ELECTRICAL_CONNECTIONS_LABEL = "Check Electrical Connections";
export const CHECK_HP_LP_PRESSURE_SWITCH_LABEL = "Check HP & LP Pressure Switch";
export const CHECK_ELECTRICAL_THERMOSTAT_LABEL = "Check Electrical Thermostat Operation";
export const CHECK_HEATER_BOOST_PUMPS_LABEL = "Check Heater Boost Pump for Leaks";
export const CHECK_CLUTCH_IDLER_PULLEY_BEARING_LABEL = "Check Clutch & Idler Pulley Bearing for Noise";
export const CHECK_EVAPORATOR_CONDENSER_FAN_MOTORS_LABEL = "Check Evaporator & Condenser Fan Motors (change brushes if needed)";
export const FUNCTION_TEST_AC_COOL_HEAT_MODE_LABEL = "Function Test A/C System Check Cool Mode, Heat Mode & Set Points";


/* Work Instructions */
export const WORK_INSTRUCTIONS_CUSTOMER_COMPLAINT_LABEL = "WORK INSTRUCTIONS | CUSTOMER COMPLAINT";

// Constants
export const SERVICE_CONSTANT = "service";
export const AC_FAULTY_CONSTANT = "ac_faulty";
export const AC_REPAIRS_CONSTANT = "ac_repairs";
export const RCF_CONSTANT = "rcf";
export const WARRANTY_REPAIRS_CONSTANT = "warranty_repairs";
export const PREVENTATIVE_MAINTENANCE_CONSTANT = "preventative_maintenance";
export const OIL_CHANGED_CONSTANT = "oil_changed";
export const CHANGED_FILTER_DRIER_CONSTANT = "filter_driver";
export const CHANGED_SHAFT_SEAL_CONSTANT = "shaft_seal";

// Labels
export const SERVICE_LABEL = "Service";
export const AC_FAULTY_LABEL = "A/C Faulty";
export const AC_REPAIRS_LABEL = "A/C Repairs";
export const RCF_LABEL = "RCF";
export const WARRANTY_REPAIRS_LABEL = "Warranty Repairs";
export const PREVENTATIVE_MAINTENANCE_LABEL = "Preventative Maintenance";
export const OIL_CHANGED_LABEL = "Oil Changed";
export const CHANGED_FILTER_DRIER_LABEL = "Changed Filter Drier";
export const CHANGED_SHAFT_SEAL_LABEL = "Changed Shaft Seal";


/* Work Performed */
export const WORK_PERFORMED_LABEL = "WORK PERFORMED";


/* Temp & Refrigerant */
export const AMBIENT_TEMP_LABEL = "Ambient Temp:";
export const SUPPLY_AIR_TEMP_LABEL = "Supply Air Temp:";
export const REFRIGERANT_LABEL = "Refrigerant";
export const RECOVERED_LABEL = "Recovered";
export const RECHARGED_LABEL = "Recharged";
export const CAPACITY_LABEL = "Capacity";
export const TOTAL_WEIGHT_LABEL = "Total Weight"


/* Part Table */
export const QTY_LABEL = "QTY";
export const PART_NO_LABEL = "PART NO";
export const DESCRIPTION_LABEL = "DESCRIPTION";
export const LOCATION_LABEL = "Location";
export const DISCOUNT_LABEL = "Discount";
export const PRICE_EA_LABEL = "PRICE EA";
export const TOTAL_PRICE_LABEL = "TOTAL PRICE";


/* Tech Initials */
export const TECH_INITIALS_LABEL = "Tech Initials";
export const DATE_LABEL = "Date";
export const HOURS_LABEL = "HRS.";
export const TOTAL_TIME_SPENT_LABEL = "Total Time Spent";


/* Calculation List */
export const TOTAL_MATERIAL_PRICE_LABEL = "TOTAL MATERIAL PRICE";
export const CALL_OUT_QUOTED_PRICE_LABEL = "CALL OUT | QUOTED PRICE";
export const SERVICE_COST_LABEL = "SERVICE COST";
export const TOTAL_LABOUR_COSTS_LABEL = "TOTAL LABOUR COSTS";
export const SUB_TOTAL_EXT_GST_LABEL = "SUB-TOTAL EXT GST";
export const WORKSHOP_SUPERVISOR_SIGNATURE_LABEL = "Workshop Supervisor Signature";
export const INVOICE_NUMBER_LABEL = "Invoice Number";
export const INVOICE_DATE_LABEL = "Invoice Date"


/* Service Status */
export const SERVICE_STATUS_LABEL = "SERVICE STATUS";

// Constants
export const INVOICED_CONSTANT = "invoiced";
export const ACTIVE_CONSTANT = "active";
export const NO_CHARGE_CONSTANT = "no_charge";
export const SERVICE_REPORT_COMPLETED_CONSTANT = "service_reported_completed";


// Labels
export const INVOICED_LABEL = "Invoiced";
export const ACTIVE_LABEL = "Active";
export const NO_CHARGE_LABEL = "No Charge";
export const SERVICE_REPORT_COMPLETED_LABEL = "Service Report Completed";
export const OPEN_SIGNATURE_PAD_LABEL = "OPEN SIGNATURE PAD";
