import React, {useEffect, useState} from 'react';
import TextInput from "../../form/TextInput";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {isEmpty} from "../../../utils/helpers/app.helpers";
import Loader from "../../common/Loader";

const FleetBatchModal = (props) => {
    const [init, setInit] = useState(false);
    const [initFleetBatch, setInitFleetBatch] = useState(false);
    const [initDefaultFleet, setInitDefaultFleet] = useState(false);
    const [hasAddedBox, setHasAddedBox] = useState(false);

    const [errors, setErrors] = useState({});
    const [fleetBatch, setFleetBatch] = useState({});
    const [defaultFleet, setDefaultFleet] = useState({});
    const [customers, setCustomers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const action = props.action;

    useEffect(() => {
        if(props.action === "edit"){
            getFleetBatchByUserId();
        }else{
            getDefaultFleetBatch();
        }
    }, [props.action]);

    useEffect(() => {
        if(initFleetBatch) {
            getDefaultFleet();
        }
    }, [initFleetBatch]);

    useEffect(() => {
        if(initDefaultFleet){
            getCustomers();
        }
    }, [initDefaultFleet]);

    useEffect(() => {
        if(hasAddedBox) {
            let latest_fleet = document.getElementById(`fleet_no_${fleetBatch.fleets.length}`);
            if(latest_fleet) {
                latest_fleet.focus();
            }
            setHasAddedBox(false);
        }
    }, [hasAddedBox]);

    useEffect(() => {
        if(init) {
            getFleetBatchByUserId();
        }
    }, [searchTerm]);

    useEffect(() => {
        if(init) {
            validate();
        }
    }, [fleetBatch]);

    function getDefaultFleetBatch() {
        let url = API_ENDPOINT + "v1/Fleets/getDefaultFleetBatch";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setFleetBatch(results.data);
            setInitFleetBatch(true);
        });
    }

    function getFleetBatchByUserId() {
        let url = API_ENDPOINT + "v1/Fleets/getFleetBatchByUserId";
        let params = {
            user_id: props.user_id,
            search_term: searchTerm
        };

        postAuthenticated(url, params).then(results => {
            setFleetBatch(results.data);
            setInitFleetBatch(true);
        });
    }

    function getDefaultFleet() {
        let url = API_ENDPOINT + "v1/Fleets/getDefaultFleet";
        let params = {};

        postAuthenticated(url, params).then(results => {
            let fleet = results.data;
            fleet.user_id = fleetBatch.user_id;

            setDefaultFleet(fleet);
            setInitDefaultFleet(true);
        });
    }

    function getCustomers() {
        let url = API_ENDPOINT + "v1/Users/getCustomers";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setCustomers(results.data);
            setInit(true);
        });
    }

    function saveFleetBatch() {
        let url = API_ENDPOINT + "v1/Fleets/saveFleetBatch";
        let params = {
            fleet_batch: fleetBatch
        };

        postAuthenticated(url, params).then(result => {
            props.onClose();
        });
    }

    function addFleetRow() {
        let fleets = [...fleetBatch.fleets];
        fleets[fleetBatch.fleets.length] = defaultFleet;

        setFleetBatch({...fleetBatch, fleets: fleets});
        setHasAddedBox(true);
    }

    function removeFleetRow(index) {
        let _fleetBatch = {
            ...fleetBatch,
            fleets: Object.assign([], {
                ...fleetBatch.fleets,
                [index]: {
                    ...fleetBatch.fleets[index],
                    is_active: 0
                }
            }),
        };

        setFleetBatch(_fleetBatch);
    }

    function validate(confirm = false) {
        let _errors = {};

        if(isEmpty(fleetBatch.address)) _errors['customer'] = "Customer field must not be empty";
        if(isEmpty(fleetBatch.address)) _errors['address'] = "Select a customer";
        if(isEmpty(fleetBatch.fleets[0].fleet_no)) _errors['fleet_no'] = "Fleet no cannot be empty";

        setErrors(_errors);

        if(confirm && Object.keys(_errors).length == 0){
            saveFleetBatch();
        }
    }

    function handleChange(event, index) {
        let name = event.target.name;
        let value = event.target.value;

        if(name != 'user_id') {
            let fleets = Object.assign([], {
                ...fleetBatch.fleets,
                [index]: {
                    ...fleetBatch.fleets[index],
                    [name]: value
                },
            });
            setFleetBatch({...fleetBatch, fleets: fleets});
        }else{
            if(value != null || value != ""){
                let user = customers.find(x => x.id == value);
                setFleetBatch({
                    ...fleetBatch,
                    [event.target.name]: value,
                    company_name: user.company_name,
                    address: user.address
                });
            }
        }
    }

    function handleKeyDown(event, index) {
        let name = event.target.name;
        if (event.keyCode == 13) {
            if(name == "fleet_no") {
                let next_element = document.getElementById(`rego_no_${index+1}`);
                if(next_element) {
                    next_element.focus();
                }
            }

            if(name == "rego_no") {
                let next_fleet = document.getElementById(`fleet_no_${index+2}`)
                if(next_fleet) {
                    next_fleet.focus();
                }else{
                    document.getElementById('add_btn').focus();
                }
            }
        }
    }

    return (
        initFleetBatch && initDefaultFleet && init ?
            <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable mw-80 modal-entrance">
                    <div className="modal-content w-100 h-95">
                        <div className="modal-header d-block">
                            <h1 className="col-6 fs-3">Fleet Batch Details</h1>
                            {action == "edit" &&
                                <div className="d-flex">
                                    for <span className="ms-1 text-decoration-underline fw-light">{fleetBatch.name} | {fleetBatch.email}</span>
                                </div>}
                        </div>

                        <div className="modal-body" style={{overflowY: "scroll"}}>
                            <div id="customer_div" className="mb-4">
                                <div className="w-70 m-auto mt-2">
                                    <label className="lead form-label fs-16 p-0 m-0 mb-1">Customer:</label>
                                    <select id="user_id" name="user_id" className={`form-control rounded`} onChange={(e) => handleChange(e)} required={true} style={{ border: `${errors.customer ? '1px solid red' : ''}` }}>
                                        <option value={null} selected={fleetBatch.user_id === null}>Select a customer...</option>
                                        {customers.map((customer, index) =>
                                            <option value={customer.id} selected={customer.id == fleetBatch.user_id}>{customer.company_name}</option>
                                        )}
                                    </select>
                                    {errors.customer && <div className={`lead text-danger fs-6 mt-1 mb-2`}>{errors.customer}</div> }

                                </div>

                                <div className="mt-3 w-70 m-auto">
                                    <TextInput
                                        name="address"
                                        label="Address:"
                                        value={fleetBatch.address}
                                        onChange={(e) => handleChange(e)}
                                        disabled={true}
                                        className="rounded"
                                        error={errors.address}
                                    />
                                </div>
                            </div>

                            <hr className="w-70 m-auto mb-1 text-dark" />

                            <div className="m-auto w-70">
                                <button id="add_btn" className="p-2 text-center text-secondary mt-4 w-100 m-auto bg-light" onClick={() => addFleetRow()} style={{border: "1px dashed #c9c9c9"}}>Add Fleet <i className="fa fa-plus-circle"/></button>
                            </div>

                            {props.user_id > 0 &&
                                <div className="w-70 m-auto mt-4">
                                    <TextInput
                                        name="keywords"
                                        placeholder="Search fleets"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="rounded w-100 px-2"
                                        labelClassName="mb-0"
                                    />
                                </div> }

                            <table className="w-70 m-auto mt-3">
                                <thead>
                                    <tr className="border border-2 border-start-0 border-end-0 border-top-0">
                                        <th className="col-1 text-center justify-content-center">#</th>
                                        <th className="text-secondary">Fleet No</th>
                                        <th className="text-secondary">Rego No</th>
                                        <th className="text-secondary">Brought In</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fleetBatch.fleets.map((fleet, index) =>
                                        fleet.is_active == 1 &&
                                        <tr className="border border-1 border-start-0 border-end-0">
                                            <td className="border border-0 text-center justify-content-center fw-bold">{index+1}</td>
                                            <td className="border border-0">
                                                <TextInput
                                                    id={`fleet_no_${index+1}`}
                                                    name="fleet_no"
                                                    value={fleet.fleet_no}
                                                    onChange={(e) => handleChange(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    className={`form-control rounded-0 w-100 border ${index == 0 && errors.fleet_no ? ' border-1 border-danger' : 'border-0'}`}
                                                    error={index == 0 && errors.fleet_no}
                                                />
                                            </td>
                                            <td className="border border-1 border-end-0">
                                                <TextInput
                                                    id={`rego_no_${index+1}`}
                                                    name="rego_no"
                                                    value={fleet.rego_no}
                                                    onChange={(e) => handleChange(e, index)}
                                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                                    className="form-control border border-0 rounded-0 w-100"
                                                />
                                            </td>
                                            <td className="border border-1 border-end-0">
                                                <input
                                                    type="date"
                                                    id={`brought_in_dt_${index+1}`}
                                                    name="brought_in_dt"
                                                    value={fleet.brought_in_dt}
                                                    onChange={(e) => handleChange(e, index)}
                                                    className="form-control border border-0 rounded-0 w-100"
                                                />
                                            </td>
                                            <td className="border border-0">
                                                {index !== 0 && <i role="button" className="text-danger border border-0 fa fa-trash fs-5 ms-3" onClick={() => removeFleetRow(index)} /> }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>

                            <div className="m-auto w-70">
                                <button id="add_btn" className="border border-1 p-2 text-center text-secondary mt-4 w-100 m-auto bg-light" onClick={() => {document.getElementById('customer_div').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}}>Scroll to top <i className="fa fa-arrow-up"/></button>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-primary" onClick={() => validate(true)}>{action === "create" ? 'Create' : 'Update'}</button>
                            <button className="btn btn-danger" onClick={() => props.onClose()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default FleetBatchModal;