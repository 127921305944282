import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "../../../styles/history/history.scss";

import Back from "../../common/Back";
import Loader from "../../common/Loader";
import TextInput from "../../form/TextInput";
import HistoryTable from './HistoryTable.js';
import LayoutView from "../../common/LayoutView";

import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setHistoryPageNumber} from "../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";


const History = (props) => {
    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [resetPage, setResetPage] = useState(false);
    const historyTracker = useSelector(state => state.historyTracker);
    const [reportColors, setReportColors] = useState([]);
    const [searchedServiceReports, setSearchedServiceReports] = useState([]);

    useEffect(() => {
        getReportColors();
    }, []);

    useEffect(() => {
        searchServiceReports();
    }, [historyTracker]);

    function handleChange(event) {
        setResetPage(true);
        let _historyPage = {
            ...historyTracker,
            search: event.target.value,
            page: 1,
        }
        dispatch(setHistoryPageNumber(_historyPage));
    }

    function getReportColors() {
        let url = API_ENDPOINT + 'v1/ReportColors/GetReportColors';
        let params = {}

        postAuthenticated(url, params).then(results => {
            setReportColors(results.data);
            setInit(true);
        });
    }

    function searchServiceReports() {
        let url = API_ENDPOINT + 'v1/ServiceReports/SearchServiceReports';
        let params = {
            keywords: historyTracker.search,
            service_status: historyTracker.lastSelectedFilter.id ? historyTracker.lastSelectedFilter.id : ""
        }

        postAuthenticated(url, params).then(results => {
            setSearchedServiceReports(results.data);
            setResetPage(false);
        });
    }

    function selectServiceReportsByServiceStatus(service_status) {
        setResetPage(true);
        let _historyPage = {
            ...historyTracker,
            page: 1,
            historyReportCount: 0,
            lastSelectedReport: 0,
            lastSelectedFilter: service_status,
            search: service_status.id == "" ? "" : historyTracker.search
        }
        dispatch(setHistoryPageNumber(_historyPage));
    }

    function focusOnEnter(event) {
        if (event.keyCode === 13) {
            event.preventDefault();

            if(searchedServiceReports.length > 0) {
                event.target.blur();
                let firstReport = document.getElementById(searchedServiceReports[0].id);
                if (firstReport) {
                    firstReport.focus();
                }
            }
        }
    }

    return (
        <div className='main-container'>
            <LayoutView />

            <hr />

            <h2 className="lead fs-1 text-start mt-4">Service Reports</h2>
            <div className="w-100 m-auto input-group mb-3">
                <TextInput
                    name="keywords"
                    value={historyTracker.search}
                    placeholder="Search..."
                    onChange={(e) => handleChange(e)}
                    onKeyUp={(e) => focusOnEnter(e)}
                    className="mt-1"
                />
            </div>

            {init ?
                <React.Fragment>
                    <div className="d-flex flex-wrap justify-content-center mt-3">
                        <div className="report-color-box p-3 m-1 border border-dark text-center" onClick={() => selectServiceReportsByServiceStatus({id: "", report_status: ""})} style={{width: "90px", backgroundColor: `black`}}>
                            <span className='align-middle fw-bold text-white'>HOME</span>
                        </div>
                        {reportColors.map(color => (
                            <div key={color.id} className={`report-color-box p-3 m-1 border border-dark text-center ${color.id === historyTracker.lastSelectedFilter.id ? 'active' : ''}`} onClick={() => selectServiceReportsByServiceStatus(color)} style={{width: "90px", backgroundColor: `${color.color}`}}>
                                <span className='align-middle fw-bold'>{color.count}</span>
                            </div>
                         ))}
                    </div>

                    <HistoryTable
                        reportColors={reportColors}
                        searchedReports={searchedServiceReports}
                        searchServiceReports={searchServiceReports}
                        resetPage={resetPage}
                    />
                </React.Fragment>
                :
                <Loader />
            }

            <Back />
        </div>
    );
};

History.propTypes = {

};

export default History;
